<template>
    <div class="mb-3 card transparent">

        <div>
            <v-tabs v-model="tab" class="rounded-t">
                <v-tab key="list">
                    {{ $t('erp.lang_nav_settings_gangSteuerung') }}
                </v-tab>
                <v-tab key="add">{{ $t('erp.lang_gangAdd') }}</v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item key="list">
                        <!-- Table -->
                        <Datatable v-show="showTable" ref="CourseSettingsDatatable"
                                   :api-endpoint="ENDPOINTS.DATATABLES.ERP.COURSESETTINGS"
                                   :datatable-headers="datatableHeaders"
                                   :excel-columns="excelColumns"
                                   excel-file-name="SizeVariations"
                                   @editEntry="entryEdit"
                                   @deleteEntry="entryDelete"
                                   @deleteEntries="entriesDelete"
                                   show-delete-buttons
                                   show-edit-buttons
                                   :permissionDelete="this.$store.getters['permissions/checkPermission']('courses')"
                                   :permissionEdit="this.$store.getters['permissions/checkPermission']('courses')"
                        />
                        <v-container v-if="showUpdate" fluid>
                            <v-layout>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text"
                                                  :label="$t('erp.lang_gangName')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="sorting"
                                                  :label="$t('erp.lang_GangPosition')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <fontawesome-picker :fieldLabel="$t('settings.lang_chooseCourseIcon')"
                                                        v-model="selectedIcon"></fontawesome-picker>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-select :items="orderbonPrinterIDs"
                                              :label="$t('settings.langOrderbonPrinter')"
                                              item-text="name"
                                              item-value="id"
                                              outlined
                                              v-if="this.$store.getters['permissions/checkModule'](2)"
                                              v-model="orderbonID"
                                    ></v-select>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="error" @click="deleteData">{{ $t('generic.lang_delete') }}</v-btn>
                                    <v-btn color="success" :disabled="text.length < 1" @click="updateData">{{
                                        $t('generic.lang_edit') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>

                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="add">
                        <v-container fluid>
                            <v-layout>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text"
                                                  :label="$t('erp.lang_gangName')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="sorting"
                                                  :label="$t('erp.lang_GangPosition')"
                                                  autocomplete="off"
                                                  type="number"
                                                  step="0.01"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <fontawesome-picker :fieldLabel="$t('settings.lang_chooseCourseIcon')"
                                                        v-model="selectedIcon"></fontawesome-picker>
                                </v-flex>
                                <v-flex md6 mr-2 ml-2>
                                    <v-select :items="orderbonPrinterIDs"
                                              :label="$t('settings.langOrderbonPrinter')"
                                              item-text="name"
                                              item-value="id"
                                              outlined
                                              v-if="this.$store.getters['permissions/checkModule'](2)"
                                              v-model="orderbonID"
                                    ></v-select>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="success" :disabled="text.length < 1" @click="addData">{{
                                        $t('generic.lang_add') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import {mapState} from "vuex"
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";
    import FontawesomePicker from "../../common/iconPicker";
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faBarcode,
        faCheeseburger,
        faCocktail,
        faPersonCarry,
        faCroissant,
        faFish,
        faFrenchFries,
        faGifts,
        faConciergeBell,
        faHamburger,
        faHotdog,
        faMeat,
        faPizzaSlice,
        faSalad,
        faSandwich,
        faShishKebab,
        faSoup,
        faTaco
    } from '@fortawesome/pro-light-svg-icons'


    library.add(
        faHotdog,
        faBarcode,
        faCocktail,
        faPersonCarry,
        faMeat,
        faGifts,
        faConciergeBell,
        faHamburger,
        faCheeseburger,
        faFrenchFries,
        faPizzaSlice,
        faShishKebab,
        faFish,
        faCroissant,
        faSalad,
        faTaco,
        faSandwich,
        faSoup
    );


    export default {
        components: {
            FontawesomePicker,
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                selectedIcon:null,
                orderbonID:null,
                sorting: 0,
                search: "",
                loading: false,
                showTable: true,
                showCreate: false,
                showUpdate: false,
                tab: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: true
                    },
                    {text: this.$t('erp.lang_gangName'), value: "name"},
                    {text: this.$t('erp.lang_GangPosition'), value: "sort"}
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: 'id',
                    },
                    {
                        label: this.$t('erp.lang_gangName'),
                        field: "name",
                    },
                    {
                        label: this.$t('erp.lang_GangPosition'),
                        field: "sort",
                    }
                ],
            }
        },
      computed:{
          ...mapState([
             "printer"
          ]),
        orderbonPrinterIDs(){
            let printers=[{id:0,name:'None'}];

            printers.push(...this.printer.printers.filter(printer=>{
              return printer.type===2
            }));
          return printers;
        }
      },
        watch: {
            tab: function () {
                if (this.tab === 1) {
                    this.showTable = true;
                    this.showUpdate = false;
                }
            }
        },

        methods: {

            addData: function () {
                this.axios.post(ENDPOINTS.ERP.COURSES.CREATE, {
                    addCourseName: this.text,
                    addCoursePosition: this.sorting,
                    addCourseIcon:this.selectedIcon,
                    addCourseOrderbonPrinterID:this.orderbonID,
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_actionSuccessful'),
                            color: "success"
                        });
                        this.$refs.CourseSettingsDatatable.getDataFromApi();
                        this.$refs.CourseSettingsDatatable.resetSelectedRows();
                        this.resetData();
                        this.showList();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            createNew: function () {
                this.resetData();
                this.showCreate = true;
                this.tab = 1;
                this.showTable = false;
            },
            updateData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.COURSES.UPDATE, {
                    CourseID: this.id,
                    editCourseName: this.text,
                    editCoursePosition: this.sorting,
                    editCourseIcon:this.selectedIcon,
                    editCourseOrderbonPrinterID:this.orderbonID,
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });
                        self.$refs.CourseSettingsDatatable.getDataFromApi();
                        self.$refs.CourseSettingsDatatable.resetSelectedRows();
                        self.resetData();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('erp.lang_deleteGangTopic'),
                    text: this.$t('erp.lang_deleteGang'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.COURSES.DELETE, {
                            CourseID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_deleteGangSuccess'),
                                    color: "success"
                                });

                                self.resetData();
                                self.$refs.CourseSettingsDatatable.getDataFromApi();
                                self.$refs.CourseSettingsDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            entryEdit(entry) {
                let self = this;

                self.axios.post(ENDPOINTS.ERP.COURSES.GET, {
                    CourseID: entry.id
                }).then((res) => {
                    if (res.status === 200) {
                        self.id = entry.id;
                        self.text = res.data.formfillData.textFields.editCourseName;
                        self.sorting = res.data.formfillData.textFields.editCoursePosition;
                        self.selectedIcon=res.data.formfillData.textFields.editCourseIcon;
                        self.orderbonID=res.data.formfillData.textFields.editCourseOrderbonPrinterID
                        self.showTable = false;
                        self.showUpdate = true;
                    } else {
                        Events.$emit("showSnackbar", {
                            message: self.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            entryDelete(entry) {
                this.id = entry.id;

                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            },
            resetData: function () {
                this.showCreate = false;
                this.showUpdate = false;
                this.showTable = true;
                this.id = null;
                this.sorting = 0;
                this.text = "";
            },
            showList: function () {
                this.resetData();
                this.tab = 0;
            },
        },
    }
</script>